h1, h2, h3, h4{
    margin: 0;
} 
.admin-content{
    display: flex;
    flex-direction: row;
    height: auto;
    width: 80%;
    overflow-y: auto;
    justify-content: center;
}
.column{
    flex:1;
    height: 100%;
    background-color: #333;
    border-radius: 8px;
    margin-right: 4px;
    display: flex;
    align-items: center;
    justify-content: center;
    transition: all .2s ease-in-out;
    overflow-y: hidden;
    overflow-x: hidden;
}
a.column:hover{
    opacity: .8;
}
.column:active{
    opacity: .5;
}
.column h1{
    font-size: 60px;
    margin: 0;
}
.text-purple{
    color: #b655ff;
}
.profiles-content{
    width: 80%;
}
.user-content-container{
    padding: 6px 10px;
    background-color: #ddd;
    width: 100%;
    margin-bottom: 10px;
    border-radius: 12px;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    border: 1px solid #ddd;
}
.user-content{
    width: 100%;
    margin-bottom: 10px;
    border-radius: 12px;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    border: 1px solid #ddd;
}
.user-photo{
    width:60px;
    height:60px;
    border-radius: 50%;
    background-color: #aaa;
}
.user-data{
    flex: 1;
    display: flex;
    flex-direction: row;
    align-items: center;
    padding-left: 20px;
    justify-content: space-between;
}
.user-name{
    font-size: 16px;
    font-weight: 600;

    margin-right: 10px;
}
.report-type{
     background-color: #b655ff;
     border-radius: 50px;
     font-size: 12px;
     color: #fff;
     font-weight: 600;
     padding: 5px 10px;
}
.report-pending{
    background-color: #E5004D;
    border-radius: 50px;
    font-size: 12px;
    color: #fff;
    font-weight: 600;
    padding: 5px 10px;
}
.btn-option{
    border: none;
    font-size: 30px;
    font-weight: 500;
    background-color: transparent;
    justify-content: center;
    align-items: center;
    display: flex;
    border-radius: 100%;
    border:1px solid #777;
    cursor: pointer;
    margin-left: 20px;
    background-color: #777;
    width: 30px;
    height: 30px;
}
.btn-option:active{
    opacity: .5;
}
.svg-image{
    width: 20px;
    height: 20px;
    color: #fff;
}

.opt-modal-wrapper{
    width: 100%;
    height: 100vh;
    background: #0009;
    position: fixed;
    top: 0;
    z-index: 1;
    justify-content: center;
    align-items: center;
    display: flex;
}
.opt-modal-container{
    width: 520px;
    height: auto;
    background-color: #666;
    border-radius: 12px;
    background: -webkit-linear-gradient(65deg,#E3FF2B,#E47F3C, #E5004D, #b655ff);
    position: relative;
    padding: 2px;
    box-shadow: 0px 0px 100px #fff5;
}
.opt-modal-content{
    width: 100%;
    height: 100%;
    background-color: #000;
    border-radius: 12px;
    justify-content: center;
    align-items: center;
    display: flex;
}
.user-report-option{
    height: 500px;
}

/*Block user modal content*/
.block-user-container{
    padding: 40px 0;
}
.block-user-header{
    margin-bottom: 10px;
}
.block-user-header h3{
    color: #fff;
}
.block-user-content{
    margin-bottom: 10px;
}
.block-user-footer{
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
}
.block-user-text-container{
    padding-right: 14px;
    text-align: left;
    font-size: 12px;
}
.user-data-content{
    display: flex;
    align-items: center;
    margin-bottom: 20px;
}
.user-data-photo{
    width: 60px;
    height: 60px;
    border-radius: 100%;
    background-color: #666;
}
.user-data-name{
    font-weight: 600;
    padding-left: 20px;
}
.block-content-description{
    background-color: rgb(51, 51, 51);
    border-radius: 4px;
    border: 0px solid rgb(68, 68, 68);
    resize: none;
    width: 100%;
    height: 162px;
    color: #fff;
}
.block-user-footer button{
    flex: 1;
    padding: 10px;
    border-radius: 4px;
    margin-right: 10px;
    background-color: #444;
    border: 1px solid #666;
    color: #fff;
    font-weight: 600;   
}
.block-user-footer button.block{
    flex: 1;
    padding: 10px;
    border-radius: 4px;
    margin-right: 10px;
    background-color: #e5004d;
    border: 1px solid #e5004d;
    color: #fff;
    font-weight: 600;   
}

.admin-background{
    background: linear-gradient(45deg, #C997ED, #916CCC);
    width: 100%;
    height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;
}
.admin-glass{
    width: 95%;
    height: 95%;
    overflow: hidden;
    border-radius: 20px;
    /* From https://css.glass */
    background: rgba(255, 255, 255, 0.35);
    border-radius: 16px;
    box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
    backdrop-filter: blur(11.4px);
    -webkit-backdrop-filter: blur(11.4px);
    border: 1px solid rgba(255, 255, 255, 0.28);
    
    
}
.admin-grid{
    display: grid;
    grid-template-columns: 11rem auto 20rem;
}
.admin-card{
    background: rgba(255, 255, 255, 0.35);
    border-radius: 16px;
    box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
    backdrop-filter: blur(11.4px);
    -webkit-backdrop-filter: blur(11.4px);
    border: 1px solid rgba(255, 255, 255, 0.28);
}
.photo-shadow{
    box-shadow: 0 4px 30px rgba(255, 255, 255, 0.5);
}
.search-distributors-input{
    background-color: rgb(255, 255, 255);
    padding: 4px 20px;
    border-radius: 4px;
    width: 300px;
}
.search-distributors-input input{
    width: 100%;
    border: none;
    background-color: transparent;
    outline: none;
}