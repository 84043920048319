.reports-list {
  display: flex;
  flex-direction: column;

  padding: 10px 20px;
}

.report {
  display: flex;
  align-items: center;

  max-width: 500px;

  gap: 10px;

  border-radius: 6px;
  box-shadow: 0 0 5px rgba(0, 0, 0, .5);

  padding: 8px 10px;

  position: relative;

  & + div {
    margin-top: 18px;
  }

  > span {
    position: absolute;
    top: 8px;
    right: 10px;

    font-size: 12px;
    font-weight: 500;
    color: #aaa;
  }

  > main {
    display: flex;
    flex-direction: column;
    align-items: flex-start;

    > a {
      color: #E5004D;
      text-decoration-color: #E5004D;

      text-decoration: none;

      font-weight: 600;
      font-size: 22px;

      &:hover {
        text-decoration: underline;
      }
    }

    > p {
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;

      font-size: 14px;
      color: rgba(0, 0, 0, .9);

      max-width: 300px;
    }
  }
}