*{
    outline-style: none;
    outline: none;
}
.login-header{
    padding: 20px 0;
}
.login-header h1{
    color:#fff;
    text-align: left;
}
.container{

    background-image: url('../Images/sl1.jpg');
    background-size: cover;
    background-repeat: no-repeat;
}
.glass-background{
  background: 0 0;
  backdrop-filter: blur(50px) brightness(.4) contrast(.9);
}
.input-text{
    
  color: rgba(255,255,255,.8);
  padding: 8px 12px;
  border: 0;
  box-sizing: border-box;
  background: 0 0;
  height: 46px;

  -webkit-tap-highlight-color: transparent;
  display: block;
  width: 100%;
  border: 2px solid rgba(255,255,255,.2);
  border-radius: 8px;
}

form{
    text-align: left;
}
form input{

}
form label{
    margin-bottom: 10px;
    font-size: 1rem;
    color: rgba(255,255,255,.5);
}
.btn-login{
    background-color: #E5004D;
    background: -webkit-linear-gradient(-112deg, #E5004D, #b655ff);
    color: #fff;
   
    padding: 12px 20px;
    font-size: .8125rem;
    align-self: center;
    width: max-content;
    border-radius: 400px;
    font-family: roboto,helvetica,arial,sans-serif;
    font-weight: 900;
    line-height: normal;
    letter-spacing: 1px;
    border: 0;
    outline: 0;
    cursor: pointer;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    text-transform: uppercase;
}