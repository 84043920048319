*{
    margin: 0;
    padding: 0;
}
h1,h2,h3,h4,h5{
    color:#172b4d;
}
.btn-grey{ 
    height:40px;
    padding-left:20px; 
    padding-right:20px; 
    border-radius:50px;
    font-size:14px;
    font-weight:500;
    display: flex;
    align-items: center;
}
.form-item{
    display: flex;
    justify-content: center;
    align-items: center;
    min-height: 28px;
    height: auto;
    margin-bottom: 20px;
    position:relative;
}
.form-item h5{
    text-align: right;
    width: 100%;
}
.text-input{
    border: 1px solid #ddd;
    height: 28px;
    width: 300px;
    border-radius: 4px;
    padding: 8px;
}
.text-input:focus{
    background-color: #eee;
    border:2px solid #3992ff;
    outline: none;
}
.select-input{
    border: 1px solid #ddd;
    height: 28px;
    width: 300px;
    border-radius: 4px;
    padding-left: 8px;
    cursor: pointer;
}
.select-input:focus{
    background-color: #eee;
    border:2px solid #3992ff;
    outline: none;
}
.text-area{
    border: 1px solid #ddd;
    height: auto;
    min-height: 28px;
    width: 300px;
    border-radius: 4px;
    padding: 8px;
    resize: vertical;
}
.text-area:focus{
    background-color: #eee;
    border:2px solid #3992ff;
    outline: none;
}
.btn-blue{
    padding: 8px 46px;
    border-radius: 50px;
    background-color: #3992ff;
    color: #fff;
    font-size: 14px;
    margin-right: 10px;
    cursor: pointer;
    font-weight: 500;
}
.btn-blue-disabled{
    padding: 8px 46px;
    border-radius: 50px;
    background-color: #3992ff;
    color: #fff;
    font-size: 14px;
    margin-right: 10px;
    cursor: not-allowed;
    font-weight: 500;
    opacity: .5;
}
.btn-grey-2{
    padding: 8px 46px;
    border-radius: 50px;
    background-color: #ddd;
    color: #000;
    font-size: 14px;
    margin-right: 10px;
    cursor: pointer;
    font-weight: 500;
}
.alert-error{
    position: absolute;
    bottom: 32px;
    left: 0;
    font-size: 12px;
    color: #3992ff;
}
.checkbox{ margin-right: 4px;}

.musicContainer:active, .coverContainer:active{
    opacity: .5;
}
.musicContainer{
    background-color: #e9e9ed;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;

    border: 1px solid #ddd;
    font-size: 14px;
    color: #999!important;
    padding: 3px 11px;
    border-radius: 4px;
    line-height: 1.4;
    width: 300px;
    cursor: pointer;
}
.coverContainer{
    width: 300px;
    border: 1px solid #ddd;
    background-color: #e9e9ed;
    height: 300px;
    display: flex;
    justify-content: center;
    align-items: center;
    color: #999;
    font-size: 14px;
    border-radius: 9px;
    cursor: pointer;
    position:relative;
}
.modal{
    position: absolute;
    top: 0;
    width: 100%;
    height: 100vh;
    z-index: 1;
    display: flex;
    justify-content: center;
    align-items: center;
    background: #0002;
    left: 0;
}
.modalContent{
    width: 300px;
    height: auto;
    max-height: 500px;
    background-color: #ddd;
    border-radius: 12px;
    box-shadow: -5px 9px 23px -10px rgba(0, 0, 0, .5);
}
.btn-blue-round{
    border: 1px solid #3992ff;
    padding: 4px 12px;
    border-radius: 50px;
    background-color: #3992ff;

}
.btn-grey-round{
    border: 1px solid #aaa;
    padding: 4px 12px;
    border-radius: 50px;
    background-color: #aaa;

}