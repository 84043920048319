input[type="button"]{
    border: none;
}
h3{
    margin: 12px 0px;
}
input[type="button"]:active{
    opacity: .5;
}
.genre-image{
    width: 200px;
    height: 50px;
    border-radius: 8px;
    object-fit: cover;
    display: block;
    position: relative;
}
.genre-cover{
    width: 300px;
    border: 1px solid #ddd;

    display: flex;
    justify-content: center;
    align-items: center;
    color: #999;
    font-size: 14px;
    border-radius: 4px;
    cursor: pointer;
    position: relative;
    padding: 8px 16px;
}
.genre-cover:active{
    opacity: .5;
}
.btn-save-genre{

    background-color: #3992ff;
    color: #fff;
    font-size: 14px;
    text-align: center;
    border-radius: 4px;
    padding: 8px 0px;
    margin-top: 20px;
    cursor: pointer;
    width: 100px;

}