@tailwind base;
@tailwind components;
@tailwind utilities;

body{
  overflow-x: hidden!important;
  background-color: #F9FAFB !important;
}
*:focus{
  outline: none;
}
.App {
  text-align: center;
  flex:1;
  overflow-x: hidden;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}
a{
  text-decoration: none; 
}
@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}
#error-page{
  height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
}
button{
  text-decoration: none;
  border: none;
  cursor: pointer;
}
button:active{
  opacity: .5;
}
.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}
.section{
  height: 100vh;
  width: 100%;
}
.footer{
  display: flex;
  align-items: center;
  justify-content: center;
}
.close-modal-login{
  position: absolute;
  top: 20px;
  right: 20px;
  background: transparent;
  color: #fff;
}
.section-1{
  width:100%;
  height: 100%;
  background-color: transparent;
  justify-content: center;
  align-items: center;
  display: flex;
}
.section-2{
  width:100%;
  height: 100%;
  background-color: #ddd;
  justify-content: center;
  align-items: center;
  display: flex;
}
.section-3{
  width:100%;
  height: 100%;
  background-color: #aaaa;
  justify-content: center;
  align-items: center;
  display: flex;
}
.section-text{
  font-size: 40px;
  font-weight: 600;

}
.text-degrade{
  /*background: -webkit-linear-gradient(65deg,#E3FF2B,#E47F3C, #E5004D, #b655ff);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;*/
  color: #fff;
}
.animated-background{
  background: center 100% radial-gradient(ellipse at bottom, rgba(20, 20, 20, 0.8) 10% 10%, #141414),center/400% no-repeat linear-gradient(-45deg, #E3FF2B,#E47F3C, #E5004D, #b655ff);
  animation: gradient 15s ease infinite;
}
@keyframes gradient {

  0% {
    background-position: 0 50%;
  }
  20% {
    background-position: 20% 50%;
  }
  40% {
    background-position: 40% 50%;
  }
  60% {
    background-position: 60% 50%;
  }
  80% {
    background-position: 80% 50%;
  }
  100% {
    background-position: 0 50%;
  }

}
.progress-bar-striped {
	background-image: linear-gradient(45deg,hsla(0,0%,100%,.15) 25%,transparent 0,transparent 50%,hsla(0,0%,100%,.15) 0,hsla(0,0%,100%,.15) 75%,transparent 0,transparent);
	background-size: 1rem 1rem;
}

.progress-bar-animated {
	animation: progress-bar-stripes 1s linear infinite;
}
.bg-warning {
	background-color: #fd7e14 !important;
}
.progress-bar {
	background-color: #b53836;
	color: #fff;
	flex-direction: column;
	justify-content: center;
	text-align: center;
	transition: width .6s ease;
	white-space: nowrap;
}

.text-white{
  color:#000;
}
.login-header{
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  flex:1;
  width: 100%;
  overflow: hidden;
  height: 80px;
  background-color: #000;
  padding-right: 60px;
  padding-left: 60px;
  z-index: 1;
}
.login-header::after{
  content: '';
  position: absolute;
  bottom: 0;
  height: 1px;
  width: 120%;
  left:0;
}
.header{
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  flex:1;
  width: 100%;
  overflow: hidden;
  height: 80px;
  background-color: #fff;
  padding-right: 60px;
  padding-left: 60px;
  z-index: 1;
}
.header::after{
  content: '';
  position: absolute;
  bottom: 0;
  height: 1px;
  width: 120%;
  left:0;
  /*background: -webkit-linear-gradient(65deg,#E3FF2B,#E47F3C, #E5004D, #b655ff);*/
}
.btn-login{
  color: #fff;
  font-weight: 400;
  font-size: 16px;
  border-width: 0;
  cursor: pointer;
  background: -webkit-linear-gradient(-112deg,#E3FF2B,#E47F3C, #E5004D, #b655ff);
  padding: 6px 14px;
  border-radius: 50px;
}
.btn-login:active{
  opacity: .5;
}
.img-logo{
  background-color: transparent;
  width:auto;
  height: 30px;
}
.text-black{
  color:#000;
}
.text-grey{
  color: #666;
}
.input{
  width: 200px;
  height: 80px;
  background-color: #61dafb;
}
.login-modal-wrapper{
  position: fixed;
  z-index: 1;
  width: 100%;
  height: 100vh;
  top: 0;
  left: 0;
  background-color: #0005;
  justify-content: center;
  align-items: center;
  display: flex;
  transition: all .5s ease-in-out;
}
.login-modal-container{
  width: 520px;
  height: auto;
  background-color: #666;
  border-radius: 12px;
  background: -webkit-linear-gradient(65deg,#E3FF2B,#E47F3C, #E5004D, #b655ff);
  position: relative;
  padding: 2px;
  box-shadow: 0px 0px 100px #fff5;
}
.text-left{
  text-align: left;
}
.login-modal-content{
  width: 100%;
  height: 100%;
  background-color: #000;
  border-radius: 12px;
  justify-content: center;
  align-items: center;
  display: flex;
}
.login-modal{
  display: flex;
  flex-direction: column;
  width: 70%;
  padding: 40px 0;
}
.input-text{
  padding:8px;
  border-radius: 6px;
  margin-bottom: 6px;
  background-color: #444;
  border: 1px solid #444;
  font-size: 14px;
  font-weight: 600;
  color: #fff;
}
.input-text:active{
  border: 1px solid #b655ff;
}
.login-button{
  width: 100px;
  padding: 6px;
  font-size: 14px;
  font-weight: 600;
  background-color: #b655ff;
  color: #fff;
  border-radius: 6px;
  cursor: pointer;
  border: 1px solid #b655ff;
  margin-top: 12px;
}

.login-form-content{
  flex:1;
  display: flex;
  flex-direction: column;
}
.login-form-content h3{
  margin: 0;
  margin-bottom: 12px;
}
@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
