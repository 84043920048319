.App{
    height: 100vh;
}
* { 
    -moz-box-sizing: border-box; 
    -webkit-box-sizing: border-box; 
     box-sizing: border-box; 
}
ul{
    text-decoration: none;
    list-style: none;
    margin: 0;
    padding:0;
}
a{
    text-decoration: none;
}
ul li{
    color: #000!important;
    padding: 8px 20px;
    text-align: left;
    width: 100%;
}
ul li:hover{
    color: orange;
    
}   
ul li a{
    color: #fff!important;
    
}
ul li a span{
    transition: all .2s ease-in-out;
}
ul li a span:hover{
    color:orange;
    
}
.side-bar{
    width: 200px;
    padding-top: 40px;
    background: #000;
}
.content-nav{
    flex:1;
    width: 100%;
    padding: 40px;
    overflow-y: scroll;
    overflow-x: hidden;
}
.content-container{
    display: flex;
    flex-direction: column;
    flex: 1;
}
.box-shadow{
    background-color: #fafafa;
  border-radius: 8px;
  box-shadow: 0 2px 30px -10px rgba(0,0,0, .1);
  object-fit: cover;
}
.btn-logout{
    width: 100%;
    text-align: left;
    font-size: 16px;
}