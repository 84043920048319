#form {
  font-family: Arial, sans-serif;

  max-width: 90%;
  margin: 20px auto;

  > div {
    display: flex;
    align-items: center;
    gap: 16px;

    & + * {
      margin-top: 20px;
    }

    > div {
      flex: 1;

      display: flex;
      flex-direction: column;
      gap: 4px;

      > label {
        font-size: 12px;
        font-weight: 500;

        color: #000;

        margin-bottom: 0;
      }

      > input, > select {
        height: 40px;
      }

      > textarea {
        resize: vertical;
      }

      > select:invalid {
        color: #aaa;
      }

      > select {
        > option {
          color: #000;
        }
      }

      > input,
      > textarea,
      > select {
        font-size: 12px;
        padding: 0 12px;

        border-radius: 4px;

        background-color: #f9f9f9;

        &:disabled {
          background-color: #ddd;
          border: none;
        }

        max-width: 100%;

        font-family: Arial, sans-serif;

        border: 1px solid #000;
      }

      > textarea {
        padding: 12px;
      }
    }

    > button {
      width: 100%;
      background-color: green;
      color: white;
      font-size: 18px;
      font-weight: 500;

      padding: 15px 0;

      border-radius: 8px;
    }
  }
}