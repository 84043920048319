.sidebars{
    padding-top: 40px;
    display: flex;
    flex-direction: column;
    position: relative;
}
.izenu-logo{
    margin-bottom: 40px;
}
.menu-item{
    color:"#fff";
    display: flex;
    flex-direction: row;
    cursor: pointer;
    padding: 10px 8px;
    

    align-items: center;
    transition: all .5s ease-in-out;
}
.menu-item.active{
    background-color: #C997ED;
    border-left: 2px solid #b655ff;
    border-top-right-radius: 8px;
    border-bottom-right-radius: 8px;
}
.menu-item:hover{
    background-color: #C997ED;
    border-top-right-radius: 8px;
    border-bottom-right-radius: 8px;
}
.menu-item span{
    color: #000;
}
.menu-item-icon{
    margin-right: 10px;
}